import {User} from './user.model';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BackendService} from '../shared/backend.service';
import {ApiResponseModel} from '../shared/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router, private http: HttpClient, private backend: BackendService) {
  }
  authChange = new Subject<boolean>();
  user: User;
  records: User[];

  checkAuth() {
    return new Observable<boolean>(observer => {

      console.log(`checking auth ${JSON.stringify(this.user)}`);
      if (this.user) {
        return observer.next(true);
      }

      this.backend.api('portal.getMember', {}).subscribe(
        (response: ApiResponseModel) => {
          if (response.statusCode === 'SUCCESS') {
            this.user = response.object;
            this.authChange.next(true);
            observer.next(true);
          } else {
            this.logout();
            observer.error();
          }
        },
        () => {
          this.logout();
          observer.error();
        });
    });
  }

  logout() {
    this.user = null;
    this.authChange.next(false);
    this.backend.api('portal.logout', {}).subscribe(
      (response: ApiResponseModel) => {
        this.router.navigate(['/login']).then();
      },
      () => {
        this.router.navigate(['/login']).then();
      });
  }

  getUser() {
    return this.user;
  }
}
