import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {MaterialModule} from './material.module';
import {LoginComponent} from './auth/login/login.component';
import {HomeComponent} from './home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponent} from './nav/header/header.component';
import {LaddaModule} from 'angular2-ladda';
import {HttpClientModule} from '@angular/common/http';
import {NotificationComponent} from './shared/notification/notification.component';
import {MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef} from '@angular/material';
import {ProfileComponent} from './profile/profile.component';
import {RegisterComponent} from './register/register.component';
import {CountriesComponent} from './shared/countries/countries.component';
import {TrainingComponent} from './training/training.component';
import {PostTrainingIuyComponent} from './post-training/iuy/iuy.component';
import {PostTrainingComponent} from './post-training/post-training.component';
import {DownloadDialogComponent} from './post-training/iuy/download-dialog/download-dialog.component';
import {DisclaimerDialogComponent} from './post-training/iuy/disclaimer-dialog/disclaimer-dialog.component';

import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {LandingComponent} from './landing/landing.component';
import {HangoutComponent} from './hangout/hangout.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    HangoutComponent,
    HomeComponent,
    HeaderComponent,
    NotificationComponent,
    ProfileComponent,
    RegisterComponent,
    CountriesComponent,
    TrainingComponent,
    PostTrainingComponent,
    PostTrainingIuyComponent,
    DownloadDialogComponent,
    DisclaimerDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    LaddaModule,
    HttpClientModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: MatSnackBarRef, useValue: {}},
    {provide: MAT_SNACK_BAR_DATA, useValue: {}}
  ],
  entryComponents: [NotificationComponent, DownloadDialogComponent, DisclaimerDialogComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faFacebookF, faGoogle);
  }
}
