import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {BackendService} from '../shared/backend.service';
import {ApiResponseModel} from '../shared/api-response.model';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  trainings = [];

  constructor(private backendService: BackendService) {
  }

  ngOnInit() {
  }

}
