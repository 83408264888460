import * as _ from 'lodash';
import * as moment from 'moment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment';
import {Observable, Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ApiResponseModel} from './api-response.model';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private http: HttpClient) {
    // this.url = `http://localhost:8080`;
  }
  url = environment.backendUrl; // `http://localhost:8080`; // `https://crmt.ishayoga.live`;
  useLive = false;

  loadingChange = new Subject<boolean>();

  api(endpoint: string, values: object, auth: boolean = true) {
    const cmds = endpoint.split('.');
    const params = {action: cmds[1]};

    if (auth === undefined) {
      auth = true;
    }

    const path = auth ? '_api/' : '';

    _.forEach(values, (v, k) => {
      if (v === '_today_') {
        params[k] = moment().format('YYYYMMDD');
      } else {
        params[k] = values[k];
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      withCredentials: true
    };

    this.loadingChange.next(true);
    return this.http.post(`${this.url}/${path}${cmds[0]}`, new HttpParams({fromObject: params}), httpOptions)
      .pipe(finalize(() => {
        this.loadingChange.next(false);
        console.log(`${endpoint} end`);
      }));
  }

  gformSubmit(scriptId: string, params: any) {
    const formUrl = 'https://script.google.com/macros/s/' + scriptId + '/exec';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    console.log(params);

    this.loadingChange.next(true);
    return this.http.post(formUrl, new HttpParams({fromObject: params}), httpOptions)
      .pipe(finalize(() => {
        this.loadingChange.next(false);
      }));
  }
}
