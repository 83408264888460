import { Component, OnInit } from '@angular/core';
import {ApiResponseModel} from '../shared/api-response.model';
import * as moment from 'moment';
import {BackendService} from '../shared/backend.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../shared/notification/notification.service';
import {Webinar} from '../shared/webinar.model';

@Component({
  selector: 'app-post-training',
  templateUrl: './post-training.component.html',
  styleUrls: ['./post-training.component.scss']
})
export class PostTrainingComponent implements OnInit {

  webinar: Webinar;

  constructor(private backendService: BackendService,
              private route: ActivatedRoute,
              private router: Router,
              private ntf: NotificationService) { }

  ngOnInit() {
    const webinarId = this.route.snapshot.paramMap.get('id');
    this.backendService.api('webinar.get', {webinarId})
      .subscribe(
        (rsp: ApiResponseModel) => {
          this.webinar = rsp.object;
        },
        rsp => {
          this.ntf.error(`Error: ${rsp.userFriendlyMessage}`);
        }
      );
  }

}
