import * as moment from 'moment';

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {BackendService} from '../shared/backend.service';
import {ApiResponseModel} from '../shared/api-response.model';
import {NotificationService} from '../shared/notification/notification.service';
import {Webinar} from '../shared/webinar.model';

declare var jwplayer: any;

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  webinar: Webinar;

  player: any;

  constructor(private backendService: BackendService,
              private route: ActivatedRoute,
              private router: Router,
              private ntf: NotificationService) {
  }

  ngOnInit() {
    const webinarId = this.route.snapshot.paramMap.get('id');
    this.backendService.api('webinar.get', {webinarId})
      .subscribe(
        (rsp: ApiResponseModel) => {
          this.webinar = rsp.object;
          if (!this.webinar.participantProp) {
            this.backendService.api('webinar.register', {webinarId});
          } else {
            this.webinar.participantProp.currentTime = moment.utc(this.webinar.participantProp.currentPos).format('HH:mm:ss');
          }
        },
        rsp => {
          this.ntf.error(`Error: ${rsp.userFriendlyMessage}`);
        }
      );
  }

  saveProgress() {
    if (this.player.getState() !== 'playing') {
      return;
    }

    const opts = {
      webinarId: this.webinar.webinarId,
      currentPos: Math.ceil(this.player.getPosition() * 1000)
    };

    this.backendService.api('webinar.updateSessionProgress', opts).subscribe();
  }

  startSession(resume) {
    this.player = jwplayer('player');
    this.player.setup({
      file: 'https://content.jwplatform.com/manifests/EwecixhS.m3u8',
      mediaid: 'EwecixhS'
    });

    this.player.on('play', () => {
      this.saveProgress();
      if (!this.player.timer) {
        this.player.timer = setInterval(() => this.saveProgress(), 60 * 1000); // save progress every minute
      }
    });

    this.player.on('pause', () => {
      this.saveProgress();
    });

    this.player.on('complete', () => {
      this.saveProgress();
      this.cancelTimer();
      setTimeout(() => {
        this.router.navigate(['/post-training', this.webinar.webinarId]).then();
      }, 500);
    });

    this.player.on('ready', () => {
      if (resume) {
        this.player.seek(this.webinar.participantProp.currentPos / 1000);
      }
      this.player.play();
    });
  }

  cancelTimer() {
    if (this.player.timer) {
      clearInterval(this.player.timer);
      this.player.timer = null;
    }
  }

}
