import {Component, Input, OnInit} from '@angular/core';
import {Webinar} from '../../shared/webinar.model';
import {RESOURCES} from './resources';
import {DisclaimerDialogComponent} from './disclaimer-dialog/disclaimer-dialog.component';
import {MatDialog} from '@angular/material';
import {BackendService} from '../../shared/backend.service';
import {ApiResponseModel} from '../../shared/api-response.model';
import {DownloadDialogComponent} from './download-dialog/download-dialog.component';
import {HOSTED_URL, PROMO_MATERIALS} from './promo-materials';
import {NgForm} from '@angular/forms';
import {AuthService} from '../../auth/auth.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-post-training-iuy',
  templateUrl: './iuy.component.html',
  styleUrls: ['./iuy.component.scss']
})
export class PostTrainingIuyComponent implements OnInit {
  step: string;
  @Input() webinar: Webinar;

  items = RESOURCES;
  promo = PROMO_MATERIALS;
  server = HOSTED_URL;
  isSubmitting = false;
  submitted = false;

  durations = [
    '45 mins', '60 mins', '90 mins'
  ];

  languages = [
    'English', 'Mandarin', 'Cantonese', 'Tamil'
  ];

  constructor(public dialog: MatDialog, private backendService: BackendService, private authService: AuthService) {
  }

  ngOnInit() {
  }

  openDownloadDialog(item, file): void {
    if (!this.webinar.participantProp.postSessionConfirmationMs) {
      this.openDisclaimerDialog(item, file);
      return;
    }

    this.dialog.open(DownloadDialogComponent, {
      width: '500px',
      data: { item, file }
    });
  }

  openDisclaimerDialog(item, file ): void {
    const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backendService.api('webinar.updatePostSessionConfirmation', { webinarId: this.webinar.webinarId })
          .subscribe((resp: ApiResponseModel) => {
            this.webinar.participantProp = resp.object;
            this.openDownloadDialog(item, file);
          });
      }
    });
  }

  onHostSessionSubmit(f: NgForm) {

    const user = this.authService.getUser();
    const params = {
      startDate: f.value.sessionDateTime.format('DD/MM/YYYY'),
      endDate: f.value.sessionDateTime.format('DD/MM/YYYY'),
      programType: 'Isha Upa Yoga',
      duration: f.value.duration.replace(' mins', ''),
      language: f.value.language,
      address: f.value.streetAddress,
      areaLocality: f.value.city,
      cityState: f.value.stateProvince,
      postalCode: f.value.postalCode,
      country: f.value.country,
      contactInfo: f.value.contactInfo,
      yourName: user.firstName + ' ' + user.lastName,
      sessionReference: 'IND-IUY-' + Date.now() + Math.random().toString(36).slice(-5)
    };

    this.isSubmitting = true;
    this.backendService.gformSubmit('AKfycbyp0xQ81tttpKjgYHWxypSHQA9hp7p1MUsrOzOwMOVKM_w2SEy7', params)
      .pipe(
        finalize(() => { this.isSubmitting = false; })
      )
      .subscribe(() => {
        this.submitted = true;
      });
  }

  onClearForm(f: NgForm) {
    f.resetForm();
    this.submitted = false;
  }


}
