import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../auth.service';
import {NotificationService} from '../../shared/notification/notification.service';
import {Router} from '@angular/router';
import {faGoogle, faFacebookF, faMicrosoft, faYahoo} from '@fortawesome/free-brands-svg-icons';
import {BackendService} from '../../shared/backend.service';
import {PlatformLocation} from '@angular/common';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {finalize} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {ApiResponseModel} from '../../shared/api-response.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loadingStatus = {
    google: false,
    facebook: false
  };
  faGoogle = faGoogle;
  faEmail = faEnvelope;
  faFacebookF = faFacebookF;
  faMicrosoft = faMicrosoft;
  faYahoo = faYahoo;
  googleLoginLink: string;
  facebookLoginLink: string;
  microsoftLoginLink: string;
  yahooLoginLink: string;
  onSuccessUrl: string;
  @ViewChild('oAuthForm', null) oAuthForm: ElementRef;

  constructor(private authService: AuthService, private platformLocation: PlatformLocation, private router: Router,
              private ntf: NotificationService, private backend: BackendService, public dialog: MatDialog) {

  }

  ngOnInit() {
    // @ts-ignore
    this.onSuccessUrl = `${this.platformLocation.location.origin}/profile`;
    this.googleLoginLink = `${this.backend.url}/auth/login/google`;
    this.facebookLoginLink = `${this.backend.url}/auth/login/facebook`;
    this.microsoftLoginLink = `${this.backend.url}/auth/login/microsoft`;
    this.yahooLoginLink = `${this.backend.url}/auth/login/yahoo`;
  }

  login(event: any, type: string) {
    this.loadingStatus[type] = true;
    this.ntf.hide();
    this.ntf.msg('Logging in...');
    setTimeout(() => {
      event.target.submit();
    }, 0);
  }
}
