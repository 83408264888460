export interface Resource {
  lang: string;
  type: string;
  files?: ResourceFile[];
}

export interface ResourceFile {
  title: string;
  zip?: boolean;
  pdf?: boolean;
  link?: string;
  duration?: string;
  media?: MediaInfo[];
}

export interface MediaInfo {
  r: string;
  size: string;
  link: string;
}

export const RESOURCES: Resource[] = [
  {
    lang: 'English',
    type: 'Documents',
    files: [
      {
        title: 'Presentation Materials',
        zip: true,
        link: 'http://files.ishayoga.sg/iuy/en/presentation-materials.zip'
      }
    ]
  },
  {
    lang: 'English',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '30mins',
        media: [
          { r: '960x540', size: '260MB', link: 'http://files.ishayoga.sg/iuy/en/30mins-960x540.mp4' }
        ]
      },
      {
        title: 'Session Video',
        duration: '45mins',
        media: [
          { r: '1280x720', size: '410MB', link: 'http://files.ishayoga.sg/iuy/en/45mins-1280x720.mp4' }
        ]
      },
      {
        title: 'Session Video',
        duration: '60mins',
        media: [
          { r: '1920x1080', size: '2.65GB', link: 'http://files.ishayoga.sg/iuy/en/60mins-1920x1080.mp4' }
        ]
      },
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '960x540', size: '682MB', link: 'http://files.ishayoga.sg/iuy/en/90mins-960x540.mp4' }
        ]
      },
      {
        title: 'Corrections Module',
        duration: '48mins',
        media: [
          { r: '960x540', size: '233MB', link: 'http://files.ishayoga.sg/iuy/en/corrections48mins-960x540.mp4' }
        ]
      },
      {
        title: 'Namaskar For All',
        duration: '3mins',
        media: [
          { r: '1920x1080', size: '22MB', link: 'http://files.ishayoga.sg/iuy/en/namaskar-1920x1080.mp4' }
        ]
      },
      {
        title: 'Opening Slide - Yoga Padhi',
        duration: '30mins',
        media: [
          { r: '1920x1080', size: '283MB', link: 'http://files.ishayoga.sg/iuy/en/yoga-padhi-opening-slide-1920x1080.mp4' }
        ]
      },
      {
        title: 'Introduction to Inner Engineering',
        duration: '10mins',
        media: [
          { r: '720z576', size: '155MB', link: 'http://files.ishayoga.sg/iuy/en/ie-intro-720x576.mp4' }
        ]
      }
    ]
  },
  {
    lang: 'Chinese',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1920x1080', size: '1.48GB', link: 'http://files.ishayoga.sg/iuy/zh/90mins-1920x1080.mp4' }
        ]
      },
    ]
  },
  {
    lang: 'Korean',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1280x720', size: '450MB', link: 'http://files.ishayoga.sg/iuy/ko/90mins-1280x720.mp4' }
        ]
      },
    ]
  },
  {
    lang: 'Hindi',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1024x576', size: '685MB', link: 'http://files.ishayoga.sg/iuy/hi/90mins-1024x576.mp4' }
        ]
      },
    ]
  },
  {
    lang: 'Tamil',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1280x720', size: '717MB', link: 'http://files.ishayoga.sg/iuy/ta/90mins-1280x720.mp4' }
        ]
      },
      {
        title: 'Session Video',
        duration: '45mins',
        media: [
          { r: '1280x720', size: '398MB', link: 'http://files.ishayoga.sg/iuy/ta/45mins-1280x720.mp4' }
        ]
      },
    ]
  },
  {
    lang: 'Telugu',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1024x576', size: '669MB', link: 'http://files.ishayoga.sg/iuy/te/90mins-1024x576.mp4' }
        ]
      },
      {
        title: 'Session Video',
        duration: '45mins',
        media: [
          { r: '1280x720', size: '362MB', link: 'http://files.ishayoga.sg/iuy/te/45mins-1024x576.mp4' }
        ]
      },
    ]
  }
];
