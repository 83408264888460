import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {NotificationService} from '../shared/notification/notification.service';
import {BackendService} from '../shared/backend.service';
import {ApiResponseModel} from '../shared/api-response.model';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  isLoading = false;
  constructor(private ntf: NotificationService, private backendService: BackendService, private authService: AuthService) {
  }

  ngOnInit() {
  }
}
