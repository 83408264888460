import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Subscription} from 'rxjs';
import {BackendService} from '../../shared/backend.service';
import {User} from '../../auth/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User;
  authSub: Subscription;
  loadingSub: Subscription;
  loading = false;
  hangoutWhitelisted: boolean;
  hangoutWhitelistedUsers = [
      'thulasidhar@gmail.com',
      'ashwinivelangaya@gmail.com',
      'peters.ben.0007@gmail.com',
      'sharmila.napa@gmail.com',
      'nidhi.jain@ishafoundation.org',
      'sjawaji@gmail.com',
      'hpkv16@gmail.com',
      'patmaselvi@gmail.com',
      'kanni.renganathan@gmail.com',
      'kripa.shivamurthy@gmail.com',
      'thinaisha@gmail.com',
      'sharmila.napa@gmail.com',
      'abhilashemail@gmail.com',
      'richa2608@gmail.com',
      'chankjai@gmail.com',
      'seemamenon07@gmail.com',
      'bhmarwaha@gmail.com',
      'thakshayini@gmail.com',
      'jean.esther@ishafoundation.org',
      'divp.112@gmail.com',
      'nidhi.isha@gmail.com',
      'khoslem@tcd.ie',
      'kourosh06@gmail.com',
      'gaurav3@gmail.com',
  ];

  constructor(private authService: AuthService, private backendService: BackendService) {
    this.authSub = this.authService.authChange.subscribe(authStatus => {
      if (authStatus) {
        this.authService.checkAuth().subscribe(isAuth => {
          this.user = this.authService.getUser();
          this.hangoutWhitelisted = this.hangoutWhitelistedUsers.includes(this.user.email);
        });
      } else {
        this.user = null;
      }
    });
    this.loadingSub = this.backendService.loadingChange.subscribe(loading => {
      console.log(loading);
      this.loading = loading;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    this.loadingSub.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

}
