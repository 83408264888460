import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {NotificationService} from '../shared/notification/notification.service';
import {finalize} from 'rxjs/operators';
import {ApiResponseModel} from '../shared/api-response.model';
import {BackendService} from '../shared/backend.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  countries: [];
  isLoading = false;

  constructor(private ntf: NotificationService, private backendService: BackendService, private router: Router) {
  }

  ngOnInit() {
  }

  onSubmit(f) {
  }
}
