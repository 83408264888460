import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {BackendService} from '../shared/backend.service';
import {ApiResponseModel} from '../shared/api-response.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  trainings = [];

  constructor(private backendService: BackendService) {
  }

  ngOnInit() {
    this.backendService.api('webinar.query', {})
      .subscribe((resp: ApiResponseModel) => {
        if (resp.statusCode === 'SUCCESS') {
          this.trainings = resp.object;
          this.trainings.forEach( t => {
            if (t.name === 'ishaupayogafacilitatortraining' || t.name === 'ishaupayogatraining') {
              t.img = 'iuyt';
            }
            if (!t.participantProp) {
              t.participantProp = {};
            }
          });
        }
      });
  }

}
