import {NgModule} from '@angular/core';
import {
  MatButtonModule, MatCardModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSelectModule,
  MatToolbarModule,
} from '@angular/material';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MAT_DATETIME_FORMATS, MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule
  ],
  exports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule
  ],
  providers: [
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'ddd, D MMM YYYY',
          monthInput: 'MMMM',
          timeInput: 'LT',
          datetimeInput: 'ddd, D MMM YYYY LT'
        },
        display: {
          dateInput: 'ddd, D MMM YYYY',
          monthInput: 'MMMM',
          datetimeInput: 'ddd, D MMM YYYY LT',
          timeInput: 'LT',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
          popupHeaderDateLabel: 'ddd, DD MMM'
        }
      }
    }
  ]
})
export class MaterialModule {
}
