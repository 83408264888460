import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {NotificationService} from '../shared/notification/notification.service';
import {BackendService} from '../shared/backend.service';
import {ApiResponseModel} from '../shared/api-response.model';
import {AuthService} from '../auth/auth.service';
import {User} from '../auth/user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './hangout.component.html',
  styleUrls: ['./hangout.component.scss']
})
export class HangoutComponent implements OnInit {
  user: User;
  postUrl: string;
  @ViewChild('form', null) form;

  constructor(private ntf: NotificationService, private backend: BackendService, private authService: AuthService) {
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.postUrl = `${this.backend.url}/_api/portal`;
    setTimeout(() => {
      this.form.nativeElement.submit();
    }, 0);
  }
}
